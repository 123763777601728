import React from 'react'
import Head from '../components/head'
import Layout from '../components/layout'
import '../styles/grid.scss'
import '../styles/index.scss'

const productCircleRules = () => {
    return (
        <Layout>
            <Head title="Çember ile Ürün Alma Kuralları" description="Çember yakınlarınla bir araya gelerek altın günü modeli ile birikim yaparak istediğin ürünleri almanı sağlayan bir uygulamadır. Çember ile ürün alma kurallarını bu sayfada bulabilirsin."  />
            <div className="row productRules">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="hero-text">
                <h1>Çember ile Nasıl Ürün Alırsın? <span role="img" aria-label="emoji">✍️</span></h1>
                <p>
                Çember Mağaza’da almak istediğin ürünü seçerek bir çember oluşturabilir, o ürünü için birikim yapmaya başlayabilirsin. Ödeme alma sıran geldiğinde ürün için gerekli tutarı biriktirmiş olacaksın. 
                </p>
                <h3>Ürün Türleri</h3>
                <div>
                    <p>Uygulamadaki mağazada bulunan ürünlerden birini seçerek, bu ürünü almak amacıyla uygulama üzerinden oluşturulan çemberlere <b>ürün çemberi</b> denir.</p>
                  <ul>
                    <li>
                    <b>İndirimli Ürün:</b> Anlaşmalı tedarikçiler tarafından, birikim yapan kullanıcılara indirim kodu sağlanan ürünler.
                     </li>
                    <li>
                    <b>Kampanyalı Ürün:</b> Birikim hedefi olarak seçilmesi durumunda Çember tarafından, ürünü satın alırken kullanılabilecek hediye çeki ile destek sağlanan ürünler.
                    </li>
                    <li>
                    <b>Hedef Ürün:</b> Yalnızca birikim hedefi olarak seçilebilmesi amacıyla mağazaya eklenmiş, herhangi bir avantaj sağlanmayan ürünler.
                    </li>
                    <li>
                    Hedeflenen <b>kişi sayısına</b> ulaştığınızda çember başlar. 
                    </li>                   
                  </ul>
                </div>
                <div>
                    <h3>Çember ile Al</h3>
                    <p>
                    Bu butonu gördüğün ürünlerde bu seçeneği kullanarak bir çember başlatman durumunda çemberde ödeme alma sırası sana geldiğinde ürünün türüne göre avantajlardan faydalanabileceksin.
                    </p>
                    <p>
                    <b>Kampanyalı</b> veya <b>indirimli ürünleri</b> seçerek oluşturduğun çemberlerde, ödeme alma sırası sana geldiği ay, indirimli ürünler için indirim kodunu, kampanyalı ürünler için ise hediye çekin üye olduğun e-posta adresine gönderilecek.
                    </p>
                </div>                
                <div>
                    <h3>Ürün için Biriktir</h3>
                    <p>
                    Bu seçenek tüm ürünlerde bulunmakta. İndirim veya hediye çeki gibi bir avantaj sağlayamadığımız bazı ürünleri de mağazada göreceksin. Bu ürünleri birikim yaparak almayı isteyeceğini düşündüğümüz için mağazaya koyduk. Amacımız senin net birikim hedefleri koymanı sağlamak ve çemberi bu ürünün tutarıyla başlatmanı kolaylaştırmak.
                    </p>
                    </div>
                <div>
                    <h3>Ya ürünü almaktan vazgeçersem?</h3>
                    <p>
                    Bir ürün için birikim yaptığında o ürünü satın almak zorunda değilsin. 
                    En nihayetinde bu bir çember ve ödeme alma sıran geldiğinde çemberdeki arkadaşların hesabına payını gönderecekler.
                    Ürünü almaktan vazgeçsen de birikim yapmış olacaksın. Biz bir kayıp göremiyoruz. <span role="img" aria-label="Emoji">✍🤓</span> 
                    </p>
                </div>
                <div>
                    <h3>Çember ile Hayallerin için Nasıl Biriktirirsin?</h3>
               <ol>
                    <li>
                    Mağazaya git ve almayı istediğin ürün sayfasından “Çember ile Al” veya “Bu ürün için Çember ile biriktir” seçeneklerinden birine tıkla.
                     </li>
                    <li>
                    Kaç kişi ile bu ürün için birikim yapacağına karar ver, ödeme gününü seç ve çember oluştur.
                    </li>
                    <li>
                    Bu ürün için birlikte birikim yapacağın arkadaşlarını çembere davet et ve çember başlasın. 
                     </li>
                    <li>
                    Ödeme alma sıran geldiğinde hayalindeki ürünü satın almak senin tercihine kalmış.
                    </li>                   
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-2"></div>
          </div>

        
        </div>  
      </div>
      </div>
        </Layout>
    )
}

export default productCircleRules